import { useEffect } from "react";
import {
    CssBaseline,
    Container,
    Box,
    Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// project import
import ThemeProvider from './theme';
import Router from "./routes";
import fetchAllData from "./api/fetchAllData";
import store from "./store/store";
import NotificationBar from "./components/Alerts/NotificationBar";
import sockets from "./api/sockets";
import { setSelectedContacts } from "./store/reducers/contactsSlice";

const GoogleSignIn = ({ onSuccess, onError }) => {
    useEffect(() => {
        const initializeGsi = () => {
            if (!window.google) {
                return;
            }

            window.google.accounts.id.initialize({
                client_id: "452164027617-92orpovsvfbjp5t42im24o506sltick6.apps.googleusercontent.com",
                callback: (response) => {
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = `${process.env.REACT_APP_API_URL}auth_with_google`;

                    const credentialInput = document.createElement('input');
                    credentialInput.type = 'hidden';
                    credentialInput.name = 'credential';
                    credentialInput.value = response.credential;

                    form.appendChild(credentialInput);
                    document.body.appendChild(form);
                    form.submit();
                },
                auto_select: true, // Автоматический выбор аккаунта
                context: 'signin',
                ux_mode: 'popup',
                itp_support: true,
                prompt_parent_id: 'g_id_onload' // Добавляем ID элемента для автоматического показа
            });

            // Добавляем невидимый элемент для автологина
            const autoSignInDiv = document.createElement('div');
            autoSignInDiv.id = 'g_id_onload';
            autoSignInDiv.style.display = 'none';
            document.body.appendChild(autoSignInDiv);



            window.google.accounts.id.renderButton(
                document.getElementById("google-signin-button"),
                {
                    type: "standard",
                    shape: "rectangular",
                    theme: "outline",
                    size: "large",
                    text: "signin_with",
                    logo_alignment: "left"
                }
            );

            // Добавляем автоматический вход
            window.google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed()) {
                    console.log('Auto sign-in cannot be displayed');
                } else if (notification.isSkippedMoment()) {
                    console.log('User closed auto sign-in');
                } else if (notification.isDismissedMoment()) {
                    console.log('User dismissed auto sign-in');
                }
            });
        };

        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = initializeGsi;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [onSuccess, onError]);

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Welcome
                </Typography>
                <div id="google-signin-button" />
            </Box>
        </Container>
    );
};

export default function App() {
    const dispatch = useDispatch();
    const needLogin = useSelector((state) => state.user.needLogin);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const contacts = useSelector((state) => state.contacts.contacts);

    useEffect(() => {
        async function fetchData() {
            const result = await fetchAllData(dispatch);
            const getUserResult = result.find(item => item.type === 'user/getUser/fulfilled');
            if (!getUserResult || getUserResult.payload.need_login) return null;
            const socket = sockets(getUserResult.payload.me.socket_token)
            return () => socket.close();
        }

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        async function updateSelectedContacts() {
            if (currentGroup) {
                const resourceSet = new Set(currentGroup.contacts_resources);
                const filteredContacts = contacts.filter((user) => resourceSet.has(user.id));
                store.dispatch(setSelectedContacts({ selectedContacts: filteredContacts }));
            }
        }

        updateSelectedContacts();
    }, [currentGroup, contacts, dispatch]);

    if (needLogin) {
        return (
            <ThemeProvider>
                <CssBaseline />
                <GoogleSignIn />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider>
            <CssBaseline />
            <NotificationBar />
            <Router />
        </ThemeProvider>
    );
}
